<template>
    <div class="container-fluid px-0">
        <div class="row row-cols-1 row-cols-lg-2 mb-n4">
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Basic Tabs</div>
                    <div class="card-body">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" data-toggle="tab" href="#basicFirst" role="tab" aria-controls="home" aria-selected="true">First</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" data-toggle="tab" href="#basicSecond" role="tab" aria-controls="profile" aria-selected="false">Second</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link disabled" data-toggle="tab" href="#basicDisabled" role="tab" aria-controls="profile" aria-selected="false">Disabled</a>
                            </li>
                        </ul>
                        <div class="tab-content mt-3">
                            <div class="tab-pane fade show active" id="basicFirst" role="tabpanel" aria-labelledby="home-tab">I'm the first tab</div>
                            <div class="tab-pane fade" id="basicSecond" role="tabpanel" aria-labelledby="profile-tab">I'm the second tab</div>
                            <div class="tab-pane fade" id="basicDisabled" role="tabpanel" aria-labelledby="contact-tab">I'm the disabled tab</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Pills Variant</div>
                    <div class="card-body">
                        <ul class="nav nav-pills" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" data-toggle="tab" href="#basicPillsFirst" role="tab" aria-controls="home" aria-selected="true">First</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" data-toggle="tab" href="#basicPillsSecond" role="tab" aria-controls="profile" aria-selected="false">Second</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link disabled" data-toggle="tab" href="#basicPillsDisabled" role="tab" aria-controls="profile" aria-selected="false">Disabled</a>
                            </li>
                        </ul>
                        <div class="tab-content mt-3">
                            <div class="tab-pane fade show active" id="basicPillsFirst" role="tabpanel" aria-labelledby="home-tab">I'm the first tab</div>
                            <div class="tab-pane fade" id="basicPillsSecond" role="tabpanel" aria-labelledby="profile-tab">I'm the second tab</div>
                            <div class="tab-pane fade" id="basicPillsDisabled" role="tabpanel" aria-labelledby="contact-tab">I'm the disabled tab</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Fill</div>
                    <div class="card-body">
                        <div>
                            <ul class="nav nav-tabs nav-fill" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" data-toggle="tab" href="#fillFirst" role="tab" aria-controls="home" aria-selected="true">First</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" data-toggle="tab" href="#fillSecond" role="tab" aria-controls="profile" aria-selected="false">Second</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" data-toggle="tab" href="#fillThird" role="tab" aria-controls="contact" aria-selected="false">Very, very long title</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link disabled" data-toggle="tab" href="#fillDisabled" role="tab" aria-controls="profile" aria-selected="false">Disabled</a>
                                </li>
                            </ul>
                            <div class="tab-content mt-3">
                                <div class="tab-pane fade show active" id="fillFirst" role="tabpanel" aria-labelledby="home-tab">I'm the first tab</div>
                                <div class="tab-pane fade" id="fillSecond" role="tabpanel" aria-labelledby="profile-tab">I'm the second tab</div>
                                <div class="tab-pane fade" id="fillThird" role="tabpanel" aria-labelledby="contact-tab">I'm the tab with the very, very long title</div>
                                <div class="tab-pane fade" id="fillDisabled" role="tabpanel" aria-labelledby="contact-tab">I'm the disabled tab</div>
                            </div>
                        </div>
                        <div class="mt-5">
                            <ul class="nav nav-pills nav-fill" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" data-toggle="tab" href="#pillsFillFirst" role="tab" aria-controls="home" aria-selected="true">First</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" data-toggle="tab" href="#pillsFillSecond" role="tab" aria-controls="profile" aria-selected="false">Second</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" data-toggle="tab" href="#pillsFillThird" role="tab" aria-controls="contact" aria-selected="false">Very, very long title</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link disabled" data-toggle="tab" href="#pillsFillDisabled" role="tab" aria-controls="profile" aria-selected="false">Disabled</a>
                                </li>
                            </ul>
                            <div class="tab-content mt-3">
                                <div class="tab-pane fade show active" id="pillsFillFirst" role="tabpanel" aria-labelledby="home-tab">I'm the first tab</div>
                                <div class="tab-pane fade" id="pillsFillSecond" role="tabpanel" aria-labelledby="profile-tab">I'm the second tab</div>
                                <div class="tab-pane fade" id="pillsFillThird" role="tabpanel" aria-labelledby="contact-tab">I'm the tab with the very, very long title</div>
                                <div class="tab-pane fade" id="piillsFillDisabled" role="tabpanel" aria-labelledby="contact-tab">I'm the disabled tab</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Justified</div>
                    <div class="card-body">
                        <div>
                            <ul class="nav nav-tabs nav-justified" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" data-toggle="tab" href="#justifiedFirst" role="tab" aria-controls="home" aria-selected="true">First</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" data-toggle="tab" href="#justifiedSecond" role="tab" aria-controls="profile" aria-selected="false">Second</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" data-toggle="tab" href="#justifiedThird" role="tab" aria-controls="contact" aria-selected="false">Very, very long title</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link disabled" data-toggle="tab" href="#justifiedDisabled" role="tab" aria-controls="profile" aria-selected="false">Disabled</a>
                                </li>
                            </ul>
                            <div class="tab-content mt-3">
                                <div class="tab-pane fade show active" id="justifiedFirst" role="tabpanel" aria-labelledby="home-tab">I'm the first tab</div>
                                <div class="tab-pane fade" id="justifiedSecond" role="tabpanel" aria-labelledby="profile-tab">I'm the second tab</div>
                                <div class="tab-pane fade" id="justifiedThird" role="tabpanel" aria-labelledby="contact-tab">I'm the tab with the very, very long title</div>
                                <div class="tab-pane fade" id="justifiedDisabled" role="tabpanel" aria-labelledby="contact-tab">I'm the disabled tab</div>
                            </div>
                        </div>
                        <div class="mt-5">
                            <ul class="nav nav-pills nav-justified" role="tablist">
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link active" data-toggle="tab" href="#pillsJustifiedFirst" role="tab" aria-controls="home" aria-selected="true">First</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" data-toggle="tab" href="#pillsJustifiedSecond" role="tab" aria-controls="profile" aria-selected="false">Second</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link" data-toggle="tab" href="#pillsJustifiedThird" role="tab" aria-controls="contact" aria-selected="false">Very, very long title</a>
                                </li>
                                <li class="nav-item" role="presentation">
                                    <a class="nav-link disabled" data-toggle="tab" href="#pillsJustifiedDisabled" role="tab" aria-controls="profile" aria-selected="false">Disabled</a>
                                </li>
                            </ul>
                            <div class="tab-content mt-3">
                                <div class="tab-pane fade show active" id="pillsJustifiedFirst" role="tabpanel" aria-labelledby="home-tab">I'm the first tab</div>
                                <div class="tab-pane fade" id="pillsJustifiedSecond" role="tabpanel" aria-labelledby="profile-tab">I'm the second tab</div>
                                <div class="tab-pane fade" id="pillsJustifiedThird" role="tabpanel" aria-labelledby="contact-tab">I'm the tab with the very, very long title</div>
                                <div class="tab-pane fade" id="piillsJustifiedDisabled" role="tabpanel" aria-labelledby="contact-tab">I'm the disabled tab</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Tabs with dropdowns</div>
                    <div class="card-body">
                        <ul class="nav nav-tabs" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" data-toggle="tab" href="#tabsDropdownHome" role="tab" aria-controls="home" aria-selected="true">Home</a>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Dropdown</a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" data-toggle="tab" href="#tabsDropdownProfile" role="tab" aria-controls="profile" aria-selected="false">Profile</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link disabled" data-toggle="tab" href="#tabsDropdownContact" role="tab" aria-controls="contact" aria-selected="false">Contact</a>
                            </li>
                        </ul>
                        <div class="tab-content mt-3" id="myTabContent">
                            <div class="tab-pane fade show active" id="tabsDropdownHome" role="tabpanel" aria-labelledby="home-tab">This is home tab</div>
                            <div class="tab-pane fade" id="tabsDropdownProfile" role="tabpanel" aria-labelledby="profile-tab">This is profile tab</div>
                            <div class="tab-pane fade" id="tabsDropdownContact" role="tabpanel" aria-labelledby="contact-tab">This is contact tab</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Pills with dropdowns</div>
                    <div class="card-body">
                        <ul class="nav nav-pills" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" data-toggle="tab" href="#pillDropdownHome" role="tab" aria-controls="home" aria-selected="true">Home</a>
                            </li>
                            <li class="nav-item dropdown">
                                <a class="nav-link dropdown-toggle" data-toggle="dropdown" href="#" role="button" aria-haspopup="true" aria-expanded="false">Dropdown</a>
                                <div class="dropdown-menu">
                                    <a class="dropdown-item" href="#">Action</a>
                                    <a class="dropdown-item" href="#">Another action</a>
                                    <a class="dropdown-item" href="#">Something else here</a>
                                    <div class="dropdown-divider"></div>
                                    <a class="dropdown-item" href="#">Separated link</a>
                                </div>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" data-toggle="tab" href="#pillDropdownProfile" role="tab" aria-controls="profile" aria-selected="false">Profile</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link disabled" data-toggle="tab" href="#pillDropdownContact" role="tab" aria-controls="contact" aria-selected="false">Contact</a>
                            </li>
                        </ul>
                        <div class="tab-content mt-3">
                            <div class="tab-pane fade show active" id="pillDropdownHome" role="tabpanel" aria-labelledby="home-tab">This is home tab</div>
                            <div class="tab-pane fade" id="pillDropdownProfile" role="tabpanel" aria-labelledby="profile-tab">This is profile tab</div>
                            <div class="tab-pane fade" id="pillDropdownContact" role="tabpanel" aria-labelledby="contact-tab">This is contact tab</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Vertical Tabs</div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-3">
                                <div class="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
                                    <a class="nav-link active" data-toggle="tab" href="#vLeftFirst" role="tab" aria-controls="home" aria-selected="true">First</a>
                                    <a class="nav-link" data-toggle="tab" href="#vLeftSecond" role="tab" aria-controls="profile" aria-selected="false">Second</a>
                                    <a class="nav-link disabled" data-toggle="tab" href="#vLeftDisabled" role="tab" aria-controls="profile" aria-selected="false">Disabled</a>
                                </div>
                            </div>
                            <div class="col-9">
                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="vLeftFirst" role="tabpanel" aria-labelledby="home-tab">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Asperiores dolor illo ipsam obcaecati provident quos. In ipsum iste non ut. Asperiores aut commodi laboriosam minima nisi possimus quia repudiandae vero.</div>
                                    <div class="tab-pane fade" id="vLeftSecond" role="tabpanel" aria-labelledby="profile-tab">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Id iure quasi voluptatem? A adipisci aspernatur, blanditiis dolor earum eius expedita iusto laborum magnam maiores natus nesciunt officia similique velit veritatis?</div>
                                    <div class="tab-pane fade" id="vLeftDisabled" role="tabpanel" aria-labelledby="contact-tab">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Autem hic inventore ipsum iure magni perferendis repudiandae soluta! Architecto, earum est fuga in ipsam iure, iusto, laborum nemo quam ullam voluptatibus.</div>
                                </div>
                            </div>
                        </div>
                        <hr>
                        <div class="row mt-4">
                            <div class="col-9">
                                <div class="tab-content">
                                    <div class="tab-pane fade show active" id="vRightFirst" role="tabpanel" aria-labelledby="home-tab">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur, assumenda consequuntur cumque dicta, distinctio eaque earum, eligendi esse id impedit in iure minus natus perspiciatis similique. Magni nesciunt nihil sapiente.</div>
                                    <div class="tab-pane fade" id="vRightSecond" role="tabpanel" aria-labelledby="profile-tab">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Ad cumque distinctio esse optio possimus quasi quo? Alias architecto deserunt ipsum, itaque magni molestias placeat possimus qui quos reprehenderit, similique, vero.</div>
                                    <div class="tab-pane fade" id="vRightDisabled" role="tabpanel" aria-labelledby="contact-tab">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aperiam, architecto aspernatur autem consequatur corporis dolor est facilis hic, labore maiores, officia omnis quidem repellendus totam veritatis. Ex facilis repellat voluptatum?</div>
                                </div>
                            </div>
                            <div class="col-3">
                                <div class="nav flex-column nav-pills" role="tablist" aria-orientation="vertical">
                                    <a class="nav-link active" data-toggle="tab" href="#vRightFirst" role="tab" aria-controls="home" aria-selected="true">First</a>
                                    <a class="nav-link" data-toggle="tab" href="#vRightSecond" role="tab" aria-controls="profile" aria-selected="false">Second</a>
                                    <a class="nav-link disabled" data-toggle="tab" href="#vRightDisabled" role="tab" aria-controls="profile" aria-selected="false">Disabled</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="card mb-4">
                    <div class="card-header">Tabs Alignment</div>
                    <div class="card-body">
                        <ul class="nav nav-tabs justify-content-center" role="tablist">
                            <li class="nav-item" role="presentation">
                                <a class="nav-link active" data-toggle="tab" href="#basicCenterFirst" role="tab" aria-controls="home" aria-selected="true">First</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link" data-toggle="tab" href="#basicCenterSecond" role="tab" aria-controls="profile" aria-selected="false">Second</a>
                            </li>
                            <li class="nav-item" role="presentation">
                                <a class="nav-link disabled" data-toggle="tab" href="#basicCenterDisabled" role="tab" aria-controls="profile" aria-selected="false">Disabled</a>
                            </li>
                        </ul>
                        <div class="tab-content mt-3">
                            <div class="tab-pane fade show active" id="basicCenterFirst" role="tabpanel" aria-labelledby="home-tab">I'm the first tab</div>
                            <div class="tab-pane fade" id="basicCenterSecond" role="tabpanel" aria-labelledby="profile-tab">I'm the second tab</div>
                            <div class="tab-pane fade" id="basicCenterDisabled" role="tabpanel" aria-labelledby="contact-tab">I'm the disabled tab</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Tabs'
    }
</script>
